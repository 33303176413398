import React, { FC, useState } from "react";
import { Layout, Menu, Button, Switch } from "antd";
import langMap, { LangMap } from "../../locales";
import { getLang } from "../../utils/lang";
import { useKeycloak } from "@react-keycloak/web";
import { FormattedMessage, useIntl } from "react-intl";
import MomentaLogo from "../../images/momenta.svg";
import BoschLogo from "../../images/bosch.svg";

type TopBarProps = {
  changeLang: (value: string) => void;
};

const genLangList = (langMap: LangMap) =>
  Object.keys(langMap).map((key) => ({ desc: langMap[key].desc, key }));
const langList = genLangList(langMap);
const { Header } = Layout;

const getFirstLevelPath = () => {
  return "/" + window.location.pathname.split("/")[1];
};

const TopBar: FC<TopBarProps> = (props: TopBarProps): JSX.Element => {
  const { changeLang } = props;
  const [curLang, setCurLang] = useState(getLang());
  const locale = useIntl();
  const { keycloak } = useKeycloak();

  const menu = (
    <Menu
      selectedKeys={[curLang]}
      onClick={(val) => {
        changeLang(val.key as string);
        setCurLang(val.key as string);
      }}
    >
      {langList.map((item) => (
        <Menu.Item key={item.key}>{item.desc}</Menu.Item>
      ))}
    </Menu>
  );
  const handleSwitch = (v) => {
    const lang = v ? "zh-CN" : "en-US";
    changeLang(lang);
    setCurLang(lang);
  };
  const firstLevelPath = getFirstLevelPath();
  let headerName = locale.messages["header.dxp"].toString();
  switch (firstLevelPath) {
    case "/dxp":
      headerName = locale.messages["header.dxp"].toString();
      break;
    case "/mil":
      headerName = locale.messages["header.mil"].toString();
      break;
  }

  return (
    <Header>
      <div className="logo">
        <img src={BoschLogo} alt="Bosch"></img>
      </div>
      <a className="logo-title" href={firstLevelPath}>
        <h1> {headerName}</h1>
      </a>
      <i style={{ flex: 2 }} />
      {!!keycloak?.authenticated ? (
        <Button
          onClick={() => {
            keycloak.logout();
            localStorage.removeItem("token");
          }}
          type="link"
        >
          <FormattedMessage id="logout" />
        </Button>
      ) : (
        <Button type="link" disabled>
          未登录
        </Button>
      )}
    </Header>
  );
};

export default TopBar;
